import {
  ref,
  computed,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'

import moment from 'moment'
import store from '@/store'
import router from '@/router'
import useToast from '@/utils/toast'

import PointLIstStoreModule from './storeModule'

export default function () {
  const { makeToast } = useToast()
  const APP_STORE_MODULE_NAME = 'app-product-list'

  const initialSearchParams = ref({})
  const currentPage = ref(parseInt(router.history.current.query.page, 10) || 1)
  const searchParams = computed(() => {
    const routerQuery = router.history.current.query
    return {
      page: currentPage.value,
      size: parseInt(routerQuery.size, 10) || 20,
      total: parseInt(routerQuery.total, 10) || 0,
      ...initialSearchParams.value,
    }
  })
  const syncronizeParamsWithUrl = () => {
    const routerQuery = router.history.current.query
    initialSearchParams.value = Object
      .keys(routerQuery)
      .reduce((previoutValue, currentValue) => {
        if (['page', 'size', 'total'].includes(currentValue)) {
          return previoutValue
        }
        if (currentValue === null || currentValue === '') {
          return previoutValue
        }
        if (typeof routerQuery[currentValue] === 'string') {
          return {
            ...previoutValue,
            [currentValue]: [routerQuery[currentValue]],
          }
        }
        return {
          ...previoutValue,
          [currentValue]: routerQuery[currentValue],
        }
      },
        {})
  }

  const replaceRouterQuery = () => {
    router.replace({
      query: { ...searchParams.value },
    }).catch(() => { })
  }

  const categoryOptions = [
    {
      label: '상품명',
      paramName: 'product_name',
    },
    {
      label: '제품번호',
      paramName: 'product_no',
    },
    {
      label: 'IDX',
      paramName: 'idx',
    },
    {
      label: '벤더번호',
      paramName: 'vendor_idx',
    },
    {
      label: '벤더명',
      paramName: 'vendor_name',
    },
    {
      label: '벤더번호(1.0)',
      paramName: 'legacy_vendor_no',
    },
    {
      label: '상품상태',
      paramName: 'state',
      format: 'checkbox',
      options: [
        { text: '판매중', value: 'ACTIVE' },
        { text: '매진', value: 'SOLDOUT' },
        { text: '승인대기중', value: 'CONFIRMING' },
        { text: '숨김상태', value: 'HIDDEN' },
        { text: '삭제', value: 'INACTIVE' },
      ],
    },
    {
      label: '판매타입',
      paramName: 'sell_type',
      format: 'checkbox',
      options: [
        { text: '상시형', value: 'ALWAYS' },
        { text: '공모형', value: 'RECRUITMENT' },
      ],
    },
    {
      label: '브랜드명',
      paramName: 'brand_name',
    },
    {
      label: '카테고리',
      paramName: 'category',
    },
    {
      label: '등록날짜',
      paramName: 'created_at',
      format: 'date',
    },
  ]

  const tableColumns = [
    { key: 'idx', label: 'idx' },
    { key: 'thumbnail_img_url', label: '이미지' },
    { key: 'vendor', label: '벤더명(벤더번호)' },
    { key: 'name', label: '상품명(브랜드명)' },
    { key: 'product_no', label: '벤더품번(태그바이품번)' },
    { key: 'category', label: '카테고리' },
    { key: 'price', label: '판매가(공급가)' },
    { key: 'sell_type', label: '판매타입' },
    { key: 'state', label: '상품상태' },
    { key: 'created_at', label: '등록날짜' },
  ]
  const items = ref([])
  const totalRows = ref(parseInt(searchParams.value.total, 10))
  const perPage = ref(parseInt(searchParams.value.size, 10) ?? 20)
  const isBusy = ref(false)

  const fetchItems = () => {
    isBusy.value = true
    store
      .dispatch(`${APP_STORE_MODULE_NAME}/fetchItems`, searchParams.value)
      .then(response => {
        const { page_info, data } = response.data
        items.value = data
        totalRows.value = page_info.total_count
        searchParams.value.total = page_info.total_count
      })
      .catch(() => {
        makeToast('danger', '목록을 쿼리하는데 실패 했습니다')
      })
      .finally(() => {
        isBusy.value = false
        replaceRouterQuery()
      })
  }

  const shortcutSearch = params => {
    initialSearchParams.value = {
      ...params,
    }
    router.replace({
      query: { ...searchParams.value, page: 1, size: 20 },
    }).catch(() => { })
    fetchItems()
  }

  const search = params => {
    initialSearchParams.value = {
      ...initialSearchParams.value,
      ...params,
    }
    currentPage.value = 1
    router.replace({
      query: { ...searchParams.value, page: currentPage.value },
    }).catch(() => { })
    fetchItems()
  }

  const changePage = params => {
    currentPage.value = params.page
    router.replace({
      query: { ...searchParams.value, ...params },
    }).catch(() => { })
    fetchItems()
  }

  onMounted(() => {
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, PointLIstStoreModule)
    syncronizeParamsWithUrl()
    fetchItems()
  })
  onUnmounted(() => {
    if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
  })

  const downloadFile = (url, name) => {
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = url
    if (name) {
      tempLink.setAttribute('download', name)
    } else {
      tempLink.setAttribute('download', '')
    }
    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
  }

  const isDownloadingExcel = ref(false)

  const downloadExcel = () => {
    isDownloadingExcel.value = true
    store
      .dispatch(`${APP_STORE_MODULE_NAME}/downloadExcel`, searchParams.value)
      .then(response => {
        const fileName = `cash_receipt_${moment().format('YYYYMMDDHHmmss')}.xlsx`
        const file = new File([response.data], fileName, {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const url = window.URL.createObjectURL(file)
        downloadFile(url, fileName)
      })
      .catch(() => {
        makeToast('danger', '엑셀 변환에 실패 했습니다')
      })
      .finally(() => {
        isDownloadingExcel.value = false
      })
  }

  return {
    searchParams,
    initialSearchParams,
    categoryOptions,
    tableColumns,
    items,
    totalRows,
    perPage,
    currentPage,
    isBusy,

    fetchItems,
    search,
    changePage,

    downloadExcel,
    isDownloadingExcel,
    shortcutSearch,
  }
}
