<template>
  <div>
    <tagby-filter
      :categoryOptions="categoryOptions"
      :initial-search-params="initialSearchParams"
      @search="search"
    >
      <template #buttons>
        <BButton
          variant="outline-secondary"
          class="mr-1"
          :to="{ name: 'commerce-product-re-create' }"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Add New</span>
        </BButton>
      </template>
    </tagby-filter>
    <tagby-list
      v-model="currentPage"
      :items="items"
      :fields="tableColumns"
      :busy="isBusy"
      :current-page="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-change="changePage"
    >
      <template #cell(idx)="data">
        <BLink :to="{ name: 'commerce-product-detail', params: { idx: data.value } }">
          {{ data.value }}
        </BLink>
      </template>

      <template #cell(thumbnail_img_url)="data">
        <BImg
          :src="data.item.thumbnail_img_url"
          width="60"
          height="60"
          rounded
        />
      </template>

      <template #cell(vendor)="data">
        <div
          v-if="data.item.vendor"
          style="width: 100px"
        >
          <div>
            {{ data.item.vendor.company_name }}
          </div>
          <div>
            (
            <span>
              {{ data.item.vendor.idx }}
            </span>
            <span
              v-if="data.item.vendor.legacy_vendor_no"
              class="text-danger"
            >
              / {{ data.item.vendor.legacy_vendor_no }}
            </span>
            )
          </div>
        </div>
      </template>

      <template #cell(name)="data">
        <div style="width: 180px">
          <BLink :to="{ name: 'commerce-product-detail', params: { idx: data.item.idx } }">
            {{ data.value }}
          </BLink>
          <div v-if="data.item.brand_name">
            ({{ data.item.brand_name }})
          </div>
        </div>
      </template>

      <template #cell(product_no)="data">
        <div style="width: 200px;">
          <div>
            {{ data.item.product_no }}
          </div>
          <div v-if="data.item.tagby_product_no">
            ({{ data.item.tagby_product_no }})
          </div>
        </div>
      </template>

      <template #cell(category)="data">
        <div style="width: 100px">
          {{ resolveCategory(data.item.category) }}
        </div>
      </template>

      <template #cell(price)="data">
        <div style="width: 80px">
          <div>
            {{ (data.item.price).toLocaleString() }}
          </div>
          <div v-if="data.item.supply_price">
            ({{ (data.item.supply_price).toLocaleString() }})
          </div>
        </div>
      </template>

      <template #cell(sell_type)="data">
        <div style="width: 50px">
          {{ resolveProductSellType(data.item.sell_type) }}
        </div>
      </template>

      <template #cell(state)="data">
        <div
          style="width: 80px"
          :class="data.value === 'CONFIRMING' ? 'text-warning' : null"
        >
          {{ resolveProductState(data.value) }}
        </div>
      </template>

      <template #cell(created_at)="data">
        <div style="width: 90px;">
          {{ resolveDateTime(data.value) }}
        </div>
      </template>
    </tagby-list>
  </div>
</template>

<script>
import {
  BLink,
  BImg,
  BButton,
} from 'bootstrap-vue'
import viewModel from './viewModel'
import TagbyFilter from '@/components/TagbyFilter.vue'
import TagbyList from '@/components/TagbyList.vue'
import resolveValue from '@/utils/resolveValue'

export default {
  components: {
    BLink,
    BImg,
    BButton,
    TagbyFilter,
    TagbyList,
  },
  setup() {
    const {
      resolveDateTime,
    } = resolveValue()
    const resolveCategory = category => [category?.class_1, category?.class_2, category?.class_3].filter(e => e != null).join(',')
    const resolveProductSellType = status => {
      if (status === 'ALWAYS') return '상시형'
      if (status === 'RECRUITMENT') return '공모형'
      return ''
    }
    const resolveProductState = status => {
      if (status === 'INACTIVE') return '삭제'
      if (status === 'ACTIVE') return '판매중'
      if (status === 'SOLDOUT') return '매진'
      if (status === 'HIDDEN') return '숨김상태'
      if (status === 'WRITING') return '작성중'
      if (status === 'DELETING') return '삭제 요청중'
      if (status === 'CONFIRMING') return '승인 대기중'
      return ''
    }
    return {
      resolveDateTime,
      resolveCategory,
      resolveProductSellType,
      resolveProductState,
      ...viewModel(),
    }
  },
}
</script>
